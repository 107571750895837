<template>
  <div class="header">
    <!-- <div class="go-back"></div> -->
    <div class="term-list" ref="termList">
      <div
        class="term"
        v-bind:class="{ active: item.termId == termId }"
        v-for="item in terms"
        :key="item.termId"
        v-on:click="goto(item.termId)"
      >
        <div>{{ item.termName }}</div>
        <div v-if="item.termId == termId" class="dot-line"></div>
      </div>
    </div>
    <div class="all-term">
      <!-- <van-icon name="apps-o" @click="isHeaderMask = true" /> -->
      <img :src="icon" @click="isHeaderMask = true" />
    </div>
    <headerMask v-if="isHeaderMask"></headerMask>
    <csWidget v-if="showCsWidget" :csId="'1'"></csWidget>
  </div>
</template>

<script>
import headerMask from "@/components/headerMask.vue";
import csWidget from "@/components/csWidget.vue";
import { termCategoryApi } from "@/api/termCategoryApi.js";
// 头部菜单
export default {
  props: {
    toPath: String,
  },
  components: {
    headerMask,
    csWidget,
  },
  data() {
    return {
      terms: [],
      termId: 1,
      isHeaderMask: false,
      icon: require("@/assets/images/header-icon.png"),
      showCsWidget: false,
    };
  },
  methods: {
    goto(id) {
      if (id < 0) {
        this.showCsWidget = true;
      } else {
        this.$router.push({
          path: this.toPath,
          query: {
            id: id,
          },
        });
      }
    },
    onListenCloseHeaderMask() {
      this.$EventBus.$on("closeHeaderMask", (val) => {
        this.isHeaderMask = val;
      });
    },

    moveHeaderScroll(id) {
      this.$nextTick(() => {
        if (id >= 4) {
          setTimeout(() => {
            document.querySelector(".term-list").scrollLeft = 200;
          }, 10);
        } else {
          setTimeout(() => {
            document.querySelector(".term-list").scrollLeft = 0;
          }, 10);
        }
      });
    },
    listenOnCloseCsWidget() {
      this.$EventBus.$on("onCloseCsWidget", (val) => {
        this.showCsWidget = false;
      });
    },
  },
  mounted() {
    termCategoryApi.get(0).then((res) => {
      if (res && res.code == 0) {
        this.terms = res.datas;
        // this.terms.push({
        //     termId: -1,
        //     termName: '领资料',
        //     parentTerm: 0,
        //     weight: 1,
        //     createTime: 1,
        //     updateTime: 1,
        // });
      }
    });

    this.onListenCloseHeaderMask();
    if (this.$route.query && this.$route.query.id) {
      this.termId = this.$route.query.id;
      this.moveHeaderScroll(this.termId);
    } else {
      this.termId = 1;
    }
    this.listenOnCloseCsWidget();
  },
  watch: {
    $route(to, from) {
      if (to.query && to.query.id && to.query.id != from.query.id) {
        this.termId = to.query.id;
        this.moveHeaderScroll(this.termId);
      } else {
        this.termId = 1;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  .big-mask {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 2;
    background: rgba(0, 0, 0, 0.8);
  }

  background: rgb(245, 246, 248);
  flex-shrink: 0;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
  .term-list {
    overflow-x: scroll;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    &::-webkit-scrollbar {
      display: none;
    }
    margin: 0px 0px 0px 17px;
    .term {
      font-size: 18px;
      color: #323233;
      letter-spacing: 0.4px;
      margin: 0px 26px 0px 0px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 10px;
      .dot-line {
        height: 4px;
        width: 28px;
        background: #4d52d1;
        border-radius: 2px;
      }
    }
    .active {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 22px;
      color: #605ddc;
      letter-spacing: 0.49px;
      padding-bottom: 4px;
      // border-bottom: 4px solid #4d52d1;
      font-weight: 600;
    }
  }
  .all-term {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 30px;
    width: 30px;
    margin: 0 17px 14px 20px;
    img {
      width: 30px;
      height: auto;
    }
  }
}
</style>
