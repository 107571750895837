<template>
  <div class="header-menu-mask">
    <div class="menu">
      <div class="header">
        <div class="title">
          全部课程
        </div>
        <div class="close" v-on:click="onClose">
          关闭
        </div>
      </div>
      <div class="tag-list">
        <div
          class="tag"
          v-bind:class="{ active: item.termId == termId }"
          v-for="item in terms"
          :key="item.termId"
          v-on:click="goto(item.termId)"
        >
          {{ item.termName }}
        </div>
      </div>
    </div>
    <csWidget v-if="showCsWidget" :csId="'1'"></csWidget>
  </div>
</template>

<script>
import { termCategoryApi } from "@/api/termCategoryApi.js";
import csWidget from "@/components/csWidget.vue";
//  展开头部菜单
export default {
  components: { csWidget },
  props: {
    toPath: String,
  },
  data() {
    return {
      terms: [],
      termId: 1,
      showCsWidget: false,
    };
  },
  methods: {
    goto(id) {
      if (id < 0) {
        this.showCsWidget = true;
      } else {
        this.$router.push({
          path: this.toPath,
          query: {
            id: id,
          },
        });
        this.onClose();
      }

      // if (id >= 4) {
      //     this.$EventBus.$emit('headerMove', 200);
      // } else {
      //     this.$EventBus.$emit('headerMove', 0);
      // }
    },
    onClose() {
      this.$EventBus.$emit("closeHeaderMask", false);
    },
    listenOnCloseCsWidget() {
      this.$EventBus.$on("onCloseCsWidget", (val) => {
        this.showCsWidget = false;
      });
    },
  },
  mounted() {
    this.listenOnCloseCsWidget();
    if (this.$route.query && this.$route.query.id) {
      this.termId = this.$route.query.id;
    } else {
      this.termId = 1;
    }

    termCategoryApi.get(0).then((res) => {
      if (res && res.code == 0) {
        this.terms = res.datas;
      }
    });
  },
  watch: {
    $route(to, from) {
      if (to.query && to.query.id && to.query.id != from.query.id) {
        this.termId = to.query.id;
      } else {
        this.termId = 1;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header-menu-mask {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  .menu {
    border-radius: 0 0 16px 16px;
    height: 330px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    .header {
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid rgb(239, 239, 239);
      padding: 0 17px 10px 17px;
      .title {
        font-size: 24px;
        color: #323233;
        letter-spacing: 0.53px;
        font-weight: 500;
        justify-content: flex-end;
        align-items: flex-end;
      }
      .close {
        font-size: 18px;
        color: #323233;
        letter-spacing: 0.4px;
        font-weight: 300;
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
    .tag-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      letter-spacing: 0.42px;
      .tag {
        margin: 10px;
        font-size: 18px;

        color: #323233;
        letter-spacing: 0.42px;
        font-weight: 500;
        height: 38px;

        background: #f2f2f2;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 14px;
      }
      .active {
        color: #ffffff;
        background: #4d52d1;
      }
    }
  }
}
</style>
