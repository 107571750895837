<template>
  <div class="latest-play">
    <div class="header">
      <div class="left">
        新品课程
      </div>
      <div class="right">
        全部课程 >
      </div>
    </div>
    <div class="card-list">
      <div class="card">
        <div class="cover">
          <img :src="imgList[0]" />
        </div>
        <div class="title">
          Python系统课
        </div>
      </div>
      <div class="card">
        <div class="cover">
          <img :src="imgList[0]" />
        </div>
        <div class="title">
          Python系统课
        </div>
      </div>
      <div class="card">
        <div class="cover">
          <img :src="imgList[0]" />
        </div>
        <div class="title">
          Python系统课
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 新品课程
export default {
  data() {
    return {
      imgList: [
        "http://public-1251206464.file.myqcloud.com/pub/1595433698062030.png",
        "http://public-1251206464.file.myqcloud.com/pub/1595434232242031.png",
      ],
      // seconds: COUNT_DOWN_SECOND,
    };
  },
};
</script>

<style lang="less" scoped>
.latest-play {
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 5px;
    .left {
      font-size: 18px;
      color: #323233;
      letter-spacing: 0.4px;
      font-weight: 500;
    }
    .right {
      font-size: 14px;
      color: #939393;
      letter-spacing: 0.31px;
      font-weight: 500;
    }
  }
  .card-list {
    overflow-x: scroll;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    .card {
      display: flex;
      flex-direction: column;
      margin: 0 7px;
      .cover {
        height: 68px;
        width: 124px;
        img {
          max-width: 100%;
          height: auto;
          border-radius: 5.76px;
        }
      }
      .title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        overflow: hidden;
        font-size: 12px;
        color: #323233;
        letter-spacing: 0.28px;
        font-weight: 500;
        font-weight: 500;
      }
    }
  }
}
</style>
